import React from "react";
import { iconsList } from "./DynamicIcons.model";

const DynamicIcon = ({ icon, size, color }) => {
  if (!iconsList[icon]) {
    return null;
  }

  const IconComponent = iconsList[icon];

  if (!IconComponent) {
    console.error(`Icon ${icon} not found in iconsList`);
    return null;
  }

  return (
    <IconComponent
      style={{ cursor: "pointer" }}
      fontSize={size}
      color={color}
    />
  );
};

export default DynamicIcon;
